import { Injectable } from '@angular/core';
import { SavePaymentRequest } from './PaymentObject';
import { GlobalDataService } from '../globaldata.service';
import { PostService } from './PostService';

@Injectable()
export class PaymentService {

    PaymentUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.PaymentUrl = this.gd.shareObj['PaymentApi'];
    }
    
    getPaymentRundoc(req: any) {
        return this.ps.post(this.PaymentUrl + "getPaymentRundoc", req);
    }

    getResultsNumber(req: any) {
        return this.ps.post(this.PaymentUrl + "getResultsNumber", req);
    }
   SavePayment(req: any) {
        return this.ps.post(this.PaymentUrl + "SavePayment", req);
    }
    getNumberRundoc(req: any) {
        return this.ps.post(this.PaymentUrl + "getNumberRundoc", req);
    }
    getAllPayment(req: any) {
        return this.ps.post(this.PaymentUrl + "getAllPayment", req);
    }
    getCCPayment(req: any) {
        return this.ps.post(this.PaymentUrl + "getCCPayment", req);
    }
    getByFilter(req: any) {
        return this.ps.post(this.PaymentUrl + "getByFilter", req);
    }
    savePaymentDelete(req: any) {
        return this.ps.post(this.PaymentUrl + "savePaymentDelete", req);
    }
    
    
    getLoanMoreByIds(req: any) {
        return this.ps.post(this.PaymentUrl + "getLoanMoreByIds", req);
    }
    getLoanMoreByMaxIds(req: any) {
        return this.ps.post(this.PaymentUrl + "getLoanMoreByMaxIds", req);
    }
    SaveLoanMore(req: any) {
        return this.ps.post(this.PaymentUrl + "SaveLoanMore", req);
    }
    getBank() {
        return this.ps.post(this.PaymentUrl + "getBank", {});
    }
    getBank2() {
        return this.ps.post(this.PaymentUrl + "getBank2", {});
    }
}
