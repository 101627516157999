import { Injectable } from '@angular/core';
import { SaveGuaranteeRequest } from './GuaranteeObject';
import { GlobalDataService } from '../globaldata.service';
import { PostService } from './PostService';

@Injectable()
export class GuaranteeService {

    GuaranteeUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.GuaranteeUrl = this.gd.shareObj['GuaranteeApi'];
    }
    getGuaranteeEditRequest(req: any) {
        return this.ps.post(this.GuaranteeUrl + "getGuaranteeRequest", req);
    }
    getGuaranteeRequest(req: any) {
        return this.ps.post(this.GuaranteeUrl + "getGuaranteeRequest", req);
    }
    saveGuaranteeNew(req: any) {
        return this.ps.post(this.GuaranteeUrl + "saveGuaranteeNew", req);
    }
    saveGuaranteeEdit(req: any) {
        return this.ps.post(this.GuaranteeUrl + "saveGuaranteeEdit", req);
    }
    saveGuaranteeDel(req: any) {
        return this.ps.post(this.GuaranteeUrl + "saveGuaranteeDel", req);
    }
    saveGuaranteeUnDel(req: any) {
        return this.ps.post(this.GuaranteeUrl + "saveGuaranteeUnDel", req);
    }
    getMaxGuaranteeCode(req: any) {
        return this.ps.post(this.GuaranteeUrl + "getMaxGuaranteeCode", req);
    }
    getGuaranteeEdit(req: any) {
        return this.ps.post(this.GuaranteeUrl + "getGuaranteeEditRequest", req);
    }
    getByIds(req: any) {
        return this.ps.post(this.GuaranteeUrl + "getByIds", req);
    }
}
