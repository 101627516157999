import { Injectable } from '@angular/core';
import { LoginRequest } from './LoginObject';
import { GlobalDataService } from '../globaldata.service';
import { PostService } from './PostService';

@Injectable()
export class LoginService {

    LoginUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.LoginUrl = this.gd.shareObj['LoginApi'];
    }

    getLoginRequest(req: LoginRequest) {
        return this.ps.post(this.LoginUrl + "getLoginRequest", req);
    }
    getBaseUserRequest(req: LoginRequest) {
        return this.ps.post(this.LoginUrl + "getBaseUserRequest", req);
    }
}
