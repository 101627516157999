import { Injectable } from '@angular/core';
import { SaveContractRequest } from './ContractObject';
import { GlobalDataService } from '../globaldata.service';
import { PostService } from './PostService';

@Injectable()
export class ContractService {

    ContractUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.ContractUrl = this.gd.shareObj['ContractApi'];
    }
    
    getContractRundoc(req: any) {
        return this.ps.post(this.ContractUrl + "getContractRundoc", req);
    }
    getContractNumber(req: any) {
        return this.ps.post(this.ContractUrl + "getContractNumber", req);
    }
    getMainBorrower(req: any) {
        return this.ps.post(this.ContractUrl + "getMainBorrower", req);
    }
    getMainBorrowerEdit(req: any) {
        return this.ps.post(this.ContractUrl + "getMainBorrowerEdit", req);
    }
    getResultsNumber(req: any) {
        return this.ps.post(this.ContractUrl + "getResultsNumber", req);
    }
    getRefRundoc(req: any) {
        return this.ps.post(this.ContractUrl + "getRefRundoc", req);
    }
    getNumberRundoc(req: any) {
        return this.ps.post(this.ContractUrl + "getNumberRundoc", req);
    }
    SaveContract(req: any) {
        return this.ps.post(this.ContractUrl + "SaveContract", req);
    }
    getBalance(req: any) {
        return this.ps.post(this.ContractUrl + "getBalance", req);
    }
    getAllContract(req: any) {
        return this.ps.post(this.ContractUrl + "getAllContract", req);
    }
    SaveLoanAgreement(req: any) {
        return this.ps.post(this.ContractUrl + "SaveLoanAgreement", req);
    }
    SaveEditLoanAgreement(req: any) {
        return this.ps.post(this.ContractUrl + "SaveEditLoanAgreement", req);
    }

//    getContractRequest(req: any) {
//        return this.ps.post(this.ContractUrl + "getContractRequest", req);
//    }
//    saveContractNew(req: any) {
//        return this.ps.post(this.ContractUrl + "saveContractNew", req);
//    }
//    saveContractEdit(req: any) {
//        return this.ps.post(this.ContractUrl + "saveContractEdit", req);
//    }
    saveContractDel(req: any) {
        return this.ps.post(this.ContractUrl + "saveContractDel", req);
    }
    saveContractUnDel(req: any) {
        return this.ps.post(this.ContractUrl + "saveContractUnDel", req);
    }
    SavePay2(req: any) {
        return this.ps.post(this.ContractUrl + "SavePay2", req);
    }
}
