/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

declare let x_tion: any;

import { PostObject } from './PostObject';


export class SaveGuaranteeRequest extends PostObject {
    code : string;
    guarantee_type : string;
    
    
    car_type : string;
    car_brand : string;
    model_year_color : string;
    registration_number : string;
    chassis_number : string;
    serial_number : string;
    registered_date : any;
    registered_age : any;
    month_per_tax : string;
    car_value : number;
    owners_name : string;
    place_property_date : any;
    give_back_date : any;
    car_ref : string;
    
    
    land_type : string;
    land_number : string;
    land_number2 : string;
    explore_page : string;
    holder_name : string;
    area1 : string;
    area2 : string;
    area3 : string;
    address1_provinces : string;
    address1_amphures : string;
    address1_district : string;
    address1_zip_code : string;
    land_place_property_date : any;
    land_give_back_date : any;
    land_ref : string;
    
    
    bank_name : string;
    branch : string;
    account_type : string;
    account_number : string;
    account_name : string;
    deposit_account_place_property_date : any;
    deposit_account_give_back_date : any;
    deposit_account_ref : string;
    
    
    lease_number : string;
    lease_date : any;
    lease_lessor : string;
    lease_tenant : string;
    lease_monthly_rent : number;
    lease_place_property_date : any;
    lease_give_back_date : any;
    lease_ref : string;
    
    
    other_detail : string;
    other_amount : number;
    other_place_property_date : any;
    other_give_back_date : any;
    other_ref : string;

    active : boolean;
    create_username : string;
    create_date : string;
    write_username : string;
    write_date : string;
    
    user_id : number;
    id : number;
    company_id : number;
    
    contract_number:string;
    contact_name:string;
    countid : number;
    guarantees_ref:string;
    
    file_detail:string;
    file_name:string;
}